const PaymentIcon = () => {
  return (
    <svg
      fill="#FFE330"
      height="800px"
      width="800px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M270.348,227.994H30.962C13.889,227.994,0,241.884,0,258.957v124.629c0,17.073,13.889,30.962,30.962,30.962h194.172 c4.736,0,8.575-3.839,8.575-8.575c0-4.736-3.839-8.575-8.575-8.575H30.962c-7.617,0-13.812-6.196-13.812-13.812v-61.331H284.16 v61.331c0,7.617-6.196,13.812-13.812,13.812h-16.632c-4.736,0-8.575,3.839-8.575,8.575c0,4.736,3.839,8.575,8.575,8.575h16.632 c17.073,0,30.962-13.889,30.962-30.962V258.957C301.31,241.884,287.421,227.994,270.348,227.994z M284.161,305.105H17.15v-22.924 h51.353c4.736,0,8.575-3.839,8.575-8.575s-3.839-8.575-8.575-8.575H17.15v-6.074c0-7.617,6.196-13.812,13.812-13.812h239.386 c7.617,0,13.812,6.196,13.812,13.812v6.074H97.085c-4.736,0-8.575,3.839-8.575,8.575s3.839,8.575,8.575,8.575h187.077V305.105z" />{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M368.047,97.452H108.154c-4.736,0-8.575,3.839-8.575,8.575v100.177c0,4.736,3.839,8.575,8.575,8.575 c4.736,0,8.575-3.839,8.575-8.575v-39.387c27.007-3.792,48.425-25.209,52.216-52.216h199.102c4.736,0,8.575-3.839,8.575-8.575 C376.621,101.291,372.782,97.452,368.047,97.452z M116.729,149.438v-34.836h34.836 C148.108,132.136,134.264,145.98,116.729,149.438z" />{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M503.425,97.452H396.63c-4.736,0-8.575,3.839-8.575,8.575c0,4.736,3.839,8.575,8.575,8.575h46.017 c3.791,27.006,25.203,48.424,52.204,52.216v71.5c-27.001,3.792-48.413,25.21-52.204,52.216H323.095 c-4.736,0-8.575,3.839-8.575,8.575c0,4.736,3.839,8.575,8.575,8.575h180.33c4.736,0,8.575-3.839,8.575-8.575V106.027 C512,101.291,508.161,97.452,503.425,97.452z M494.85,149.437c-17.529-3.458-31.369-17.302-34.825-34.835h34.825V149.437z M494.85,290.533h-34.825c3.456-17.534,17.296-31.378,34.825-34.836V290.533z" />{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M305.79,141.389c-43.52,0-78.927,27.442-78.927,61.171c0,1.434,0.07,2.921,0.206,4.42 c0.429,4.716,4.601,8.186,9.317,7.763c4.716-0.429,8.192-4.601,7.763-9.317c-0.09-0.984-0.135-1.949-0.135-2.866 c0-24.273,27.713-44.022,61.777-44.022c34.07,0,61.789,19.748,61.789,44.022c-0.002,19.932-18.949,37.438-46.079,42.569 c-4.653,0.88-7.712,5.366-6.831,10.019c0.779,4.115,4.375,6.982,8.416,6.982c0.528,0,1.066-0.049,1.603-0.151 c35.35-6.687,60.039-31.121,60.039-59.419C384.729,168.831,349.317,141.389,305.79,141.389z" />{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M93.582,339.714H38.935c-4.736,0-8.575,3.839-8.575,8.575c0,4.736,3.839,8.575,8.575,8.575h54.647 c4.736,0,8.575-3.839,8.575-8.575C102.156,343.554,98.317,339.714,93.582,339.714z" />{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default PaymentIcon;
